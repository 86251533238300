import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import {
  Table, Avatar, Tag, Input, Pagination, Select,
} from 'antd';
import qs from 'qs';
import PropTypes from 'prop-types';
import { countUsers, listUsers } from '../../services/Admin';

function Users({ match }) {
  // Pagination
  const location = useLocation();
  const history = useHistory();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const currentPage = +search.page || 1;
  const onPaginationChange = (page) => {
    const newSearch = {
      ...search,
      page,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  // Search
  const onSearchChange = (e) => {
    const newSearch = {
      ...search,
      q: e.target.value,
      page: 1,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  // Filter
  const userFilter = search.f || 'ELIGIBLE';
  const onFilterChange = (f) => {
    const newSearch = {
      ...search,
      f,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  // Data retrieving
  const [, app] = match.path.split('/');
  const { isLoading, data: users } = useQuery(
    ['listUsers', app, userFilter, currentPage, search.teamId, search.q],
    () => listUsers(app, userFilter, currentPage, search.teamId, search.q),
    { cacheTime: 0 },
  );
  const { data: count, isLoading: isCountLoading } = useQuery(
    ['countUsers', app, userFilter, search.teamId, search.q],
    () => countUsers(app, userFilter, search.teamId, search.q),
    { cacheTime: 0 },
  );
  return (
    <div id="Users">
      <div className="Filters">
        <Select
          value={userFilter}
          style={{ width: 150, marginRight: 8 }}
          onChange={onFilterChange}
        >
          <Select.Option value="ALL">All</Select.Option>
          <Select.Option value="ELIGIBLE">Eligible only</Select.Option>
          <Select.Option value="BOT">Bot only</Select.Option>
        </Select>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search in name, email and userId"
          defaultValue={search.q || ''}
          style={{ width: 260 }}
          onChange={debounce(onSearchChange, 500)}
          allowClear
        />
        <div style={{ flex: 1 }} />
        <Pagination
          current={currentPage}
          onChange={onPaginationChange}
          total={count}
          pageSize={20}
          showSizeChanger={false}
        />
      </div>
      <Table
        dataSource={users}
        loading={isLoading}
        pagination={false}
        rowKey="_id"
        size="small"
        scroll={{ x: 1500 }}
      >
        <Table.Column
          title={`User ID  (${isCountLoading ? '...' : count})`}
          dataIndex="userId"
          key="_id"
        />
        <Table.Column
          title="teamId"
          dataIndex="teamId"
          key="_id"
          render={(teamId) => (
            <a href={`/#/${app}/workspaces?q=${teamId}`}>{teamId}</a>
          )}
        />
        <Table.Column
          title="name"
          dataIndex="_id"
          key="_id"
          render={(_, user) => (
            <span>
              {user.data.profile.image_48 && (
                <Avatar
                  src={user.data.profile.image_48}
                  size={18}
                  style={{ marginRight: '8px' }}
                />
              )}
              {user.data.real_name || user.data.profile.real_name}
              {user.data.is_admin && <Tag color="red" style={{ marginLeft: '8px' }}>ADMIN</Tag>}
              {user.data.is_bot && <Tag color="blue" style={{ marginLeft: '8px' }}>BOT</Tag>}
              {user.data.deleted && ' ☠️'}
            </span>
          )}
        />
        <Table.Column
          title="Email"
          dataIndex="_id"
          key="_id"
          render={(_, user) => user.data.profile.email}
        />
        <Table.Column
          title="Job Title"
          dataIndex="_id"
          key="_id"
          render={(_, user) => user.data.profile.title}
        />
        <Table.Column
          title="Timezone"
          dataIndex="_id"
          key="_id"
          render={(_, user) => user.data.tz}
        />
      </Table>
    </div>
  );
}

Users.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default Users;
