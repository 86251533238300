/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import qs from 'qs';
import {
  Table,
  Tag,
  Pagination,
  Select,
  Input,
  Tooltip,
  Dropdown,
  Menu,
  message,
} from 'antd';
import {
  SearchOutlined,
  MoreOutlined,
  DeleteOutlined,
  UserOutlined,
  DollarOutlined,
  CrownOutlined,
} from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import {
  listWorkspaces,
  countWorkspaces,
  getStats,
  deleteWorkspace,
  grantFreePlan,
} from '../../services/Admin';
import { dateFromMongoId } from '../../utils/mongo-id';
import { COMPETITOR, APP } from '../../services/Constants';
import ConfirmModal from '../../components/ConfirmModal';

// eslint-disable-next-line react/function-component-definition
const divMaxWidth =
  (maxWidth = 110) =>
  (x) =>
    (
      <div
        style={{
          maxWidth,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {x}
      </div>
    );

function Workspaces({ match }) {
  // Pagination
  const location = useLocation();
  const history = useHistory();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const currentPage = +search.page || 1;
  const pageSize = +search.pageSize || 20;
  const onPaginationChange = (page) => {
    const newSearch = {
      ...search,
      page,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  const onPageSizeChange = (pSize) => {
    const newSearch = {
      ...search,
      page: 1,
      pageSize: +pSize,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  // Search
  const onSearchChange = (e) => {
    const newSearch = {
      ...search,
      q: e.target.value,
      page: 1,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  // Filter
  const workspaceFilter = search.f || 'ALL';
  const onFilterChange = (f) => {
    const newSearch = {
      ...search,
      f,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  // Data retrieving
  const [, app] = match.path.split('/');
  const {
    isLoading,
    data: workspaces,
    refetch: refetchList,
  } = useQuery(
    ['listWorkspaces', app, workspaceFilter, pageSize, currentPage, search.q],
    () => listWorkspaces(app, workspaceFilter, pageSize, currentPage, search.q)
  );
  const {
    data: count,
    isLoading: isCountLoading,
    refetch: refetchCount,
  } = useQuery(['countWorkspaces', app, workspaceFilter, search.q], () =>
    countWorkspaces(app, workspaceFilter, search.q)
  );
  const teamIds = (workspaces || []).map((w) => w.teamId);
  const { data: statsTmp } = useQuery({
    queryKey: ['getStats', app, teamIds.join(',')],
    queryFn: () => getStats(app, teamIds),
    enabled: !!workspaces,
  });
  const stats = statsTmp || {};
  // Actions
  const [action, setAction] = useState(null);
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const onPerformAction = async () => {
    try {
      setIsPerformingAction(true);
      if (action.type === 'FREE_FOREVER') {
        await grantFreePlan(app, action.workspace._id);
      } else if (action.type === 'DELETE') {
        await deleteWorkspace(app, action.workspace._id);
      } else {
        throw new Error(`Unknown action ${action.type}`);
      }
      message.success('Success');
      setAction(null);
      refetchList();
      refetchCount();
    } catch (error) {
      message.error('Unexpected error while performing action!');
    }
    setIsPerformingAction(false);
  };
  return (
    <div id="Workspaces">
      <div className="Filters">
        <Select
          value={workspaceFilter}
          style={{ width: 150, marginRight: 8, marginBottom: 6 }}
          onChange={onFilterChange}
        >
          <Select.Option value="ALL">All</Select.Option>
          <Select.Option value="FREE_TRIAL">Free trials only</Select.Option>
          <Select.Option value="CUSTOMER">Customers only</Select.Option>
        </Select>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search"
          defaultValue={search.q || ''}
          style={{ width: 260, marginBottom: 6 }}
          onChange={debounce(onSearchChange, 500)}
          allowClear
        />
        <div style={{ flex: 1 }} />
        <Select
          value={`${pageSize}`}
          style={{ width: 200, marginRight: 16, marginBottom: 6 }}
          onChange={onPageSizeChange}
        >
          {[20, 100, 500].map((n) => (
            <Select.Option value={`${n}`} key={n}>
              {`${n} per page`}
            </Select.Option>
          ))}
        </Select>
        <Pagination
          current={currentPage}
          onChange={onPaginationChange}
          total={count}
          pageSize={pageSize}
          showSizeChanger={false}
          style={{ marginBottom: 6 }}
        />
      </div>
      <Table
        dataSource={workspaces}
        loading={isLoading}
        pagination={false}
        rowKey="_id"
        size="small"
        scroll={{ x: 1500 }}
      >
        <Table.Column
          title={`Team ID (${isCountLoading ? '...' : count})`}
          dataIndex="teamId"
          render={divMaxWidth()}
        />
        <Table.Column
          title="Install User"
          dataIndex="installerUserId"
          key="_id"
          render={(userId, w) =>
            divMaxWidth()(
              <a href={`/#/${app}/users?q=${userId}&teamId=${w.teamId}`}>
                {userId}
              </a>
            )
          }
        />
        <Table.Column
          title="Install Date"
          dataIndex="_id"
          render={(_, w) =>
            moment(dateFromMongoId(w._id)).format('YYYY-MM-DD @ HH:mm')
          }
        />
        <Table.Column
          title=""
          dataIndex="_id"
          key="_id"
          render={(_, w) => (
            <Tooltip title={APP[app].info(w)}>
              {APP[app].status(w, stats[w.teamId])}
            </Tooltip>
          )}
        />
        <Table.Column
          title="Name"
          dataIndex="name"
          key="_id"
          render={(name, w) =>
            divMaxWidth(410)(
              <>
                {name}
                <Tag style={{ marginLeft: '8px' }}>{w.domain}</Tag>
              </>
            )
          }
        />
        <Table.Column title="Lang" dataIndex="lang" key="_id" />
        <Table.Column
          title="Origin"
          dataIndex="state"
          key="_id"
          render={(o, w) => `${o}${w.gclid ? ' + gclid' : ''}`}
        />
        {APP[app].columns.map((column) => (
          <Table.Column
            title={column.title}
            key="_id"
            render={(_, w) => column.render(w, stats[w.teamId])}
          />
        ))}
        <Table.Column
          title="⚔️"
          dataIndex="teamId"
          key="_id"
          render={(teamId) =>
            stats[teamId] && stats[teamId].competitors.length > 0
              ? stats[teamId].competitors.map((c) => (
                  <img
                    src={COMPETITOR[c.name]?.img}
                    alt={c.name}
                    title={c.name}
                    key={c.name}
                    style={{
                      width: 18,
                      borderRadius: 18,
                      marginRight: 4,
                      ...(c.deleted
                        ? {
                            filter: 'grayscale(1)',
                            opacity: 0.3,
                          }
                        : {}),
                    }}
                  />
                ))
              : '-'
          }
        />
        <Table.Column
          title="💰"
          key="_id"
          render={(_, w) =>
            w.freeTrialStartDate ? (
              <span
                style={{
                  color: moment(w.freeTrialStartDate).isBefore(
                    moment().subtract(30, 'days')
                  )
                    ? 'red'
                    : undefined,
                }}
              >
                {moment(w.freeTrialStartDate).format('YYYY-MM-DD')}
              </span>
            ) : w.stripeSubscription ? (
              '🤑'
            ) : w.buddiesSubscription ? (
              '👯‍♂️'
            ) : w.freeForever ? (
              '♾️'
            ) : (
              '-'
            )
          }
        />
        <Table.Column
          title=""
          dataIndex="teamId"
          key="_id"
          render={(teamId) => (
            <a href={`#/${app}/users?teamId=${teamId}`}>
              {`View users${
                stats[teamId] ? ` (${stats[teamId].usersCount})` : ''
              }`}
            </a>
          )}
        />
        <Table.Column
          title=""
          key="_id"
          render={(_, w) => (
            <div>
              <Dropdown
                trigger="click"
                overlay={
                  <Menu>
                    <Menu.Item
                      danger
                      onClick={() =>
                        setAction({ type: 'DELETE', workspace: w })
                      }
                    >
                      <DeleteOutlined />
                      {' Delete'}
                    </Menu.Item>
                    {w.stripeCustomer && (
                      <Menu.Item
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${process.env.API_URL}/customer-portal/${app}/${w.encryptedId}`
                          );
                          message.success('Copied to clipboard');
                        }}
                      >
                        <UserOutlined />
                        {' Customer Portal'}
                      </Menu.Item>
                    )}
                    {w.freeTrialStartDate && (
                      <Menu.Item
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${process.env.API_URL}/subscribe/${app}/${w._id}`
                          );
                          message.success('Copied to clipboard');
                        }}
                      >
                        <DollarOutlined />
                        {' Payment Link'}
                      </Menu.Item>
                    )}
                    {!w.freeForever &&
                      !w.stripeSubscription &&
                      !w.buddiesSubscription && (
                        <Menu.Item
                          onClick={() =>
                            setAction({ type: 'FREE_FOREVER', workspace: w })
                          }
                        >
                          <CrownOutlined />
                          {' Grant Free Forever'}
                        </Menu.Item>
                      )}
                  </Menu>
                }
              >
                <div style={{ cursor: 'pointer' }}>
                  <MoreOutlined />
                </div>
              </Dropdown>
            </div>
          )}
        />
      </Table>
      {action && (
        <ConfirmModal
          strConfirm={action ? action.workspace.name : ''}
          onSubmit={onPerformAction}
          onCancel={() => setAction(null)}
          loading={isPerformingAction}
          title={action ? `Confirm ${action.type}` : ''}
        />
      )}
    </div>
  );
}

Workspaces.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default Workspaces;
