import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { serializeError } from 'serialize-error';
import BuddyWrapper from './BuddyWrapper';
import Stats from './Stats';
import r from '../../utils/wrap-route-component';
import { getProfile } from '../../services/Admin';
import { APP } from '../../services/Constants';
import AppBar from '../../components/AppBar';
import Loader from '../../components/Loader';

function AppWrapper() {
  const location = useLocation();

  const redirectUrl = `/auth?next=${encodeURIComponent(JSON.stringify(location))}`;

  const hasSessionId = !!localStorage.getItem('sessionId');
  if (!hasSessionId) {
    return <Redirect to={redirectUrl} />;
  }

  const { isLoading, data, error } = useQuery(['getProfile'], getProfile, { retry: false });
  if (error?.response?.data?.error?.message === 'no sessionid found') {
    localStorage.removeItem('sessionId');
    window.location.reload(); // weird but fix a bug
    return null;
  }
  if (error) {
    return (
      <>
        {`Unknown Error: ${JSON.stringify(error.response || serializeError(error))}`}
        <br />
        --------------------
        <br />
        Please reload the page.
      </>
    );
  }

  return (
    <>
      <AppBar />
      {isLoading ? <Loader /> : null}
      <div className="PageWrapper">
        <Switch>
          {Object.keys(APP).map((key) => (
            <Route
              key={key}
              path={`/${key}`}
              render={r(BuddyWrapper, !!data)}
            />
          ))}
          <Route
            path="/stats"
            render={r(Stats, !!data)}
          />
          <Redirect
            from="*"
            to={`/${Object.keys(APP)[0]}`}
          />
        </Switch>
      </div>
    </>
  );
}

export default AppWrapper;
