import React from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getKPIs } from '../../services/Admin';
import Loader from '../../components/Loader';
import KPICard from '../../components/KPICard';

function KPIs({ match }) {
  // Data retrieving
  const [, app] = match.path.split('/');
  const { isLoading, data } = useQuery(
    ['getKPIs', app],
    () => getKPIs(app),
    { cacheTime: 0 },
  );
  return (
    <div id="KPIs">
      {isLoading && <Loader />}
      {data && (
        <div className="CardList">
          <KPICard number={data.mrr} title="$MRR" />
          <KPICard
            number={data.freeTrials.currentPeriod.count}
            title="Free Trials"
            subTitle={`${data.freeTrials.currentPeriod.start.split('T')[0]} → ${data.freeTrials.currentPeriod.end.split('T')[0]}`}
          />
          <KPICard
            number={data.freeTrials.previousPeriod.count}
            title="Free Trials"
            subTitle={`${data.freeTrials.previousPeriod.start.split('T')[0]} → ${data.freeTrials.previousPeriod.end.split('T')[0]}`}
          />
        </div>
      )}
      {data && app === 'billy' && (
        <>
          <div className="CardList">
            <KPICard number={data.slack.mrr} title="$MRR (Slack)" />
            <KPICard
              number={data.slack.freeTrials.currentPeriod.count}
              title="Free Trials (Slack)"
              subTitle={`${data.slack.freeTrials.currentPeriod.start.split('T')[0]} → ${data.slack.freeTrials.currentPeriod.end.split('T')[0]}`}
            />
            <KPICard
              number={data.slack.freeTrials.previousPeriod.count}
              title="Free Trials (Slack)"
              subTitle={`${data.slack.freeTrials.previousPeriod.start.split('T')[0]} → ${data.slack.freeTrials.previousPeriod.end.split('T')[0]}`}
            />
          </div>
          <div className="CardList">
            <KPICard number={data.teams.mrr} title="$MRR (Teams)" />
            <KPICard
              number={data.teams.freeTrials.currentPeriod.count}
              title="Free Trials (Teams)"
              subTitle={`${data.teams.freeTrials.currentPeriod.start.split('T')[0]} → ${data.teams.freeTrials.currentPeriod.end.split('T')[0]}`}
            />
            <KPICard
              number={data.teams.freeTrials.previousPeriod.count}
              title="Free Trials (Teams)"
              subTitle={`${data.teams.freeTrials.previousPeriod.start.split('T')[0]} → ${data.teams.freeTrials.previousPeriod.end.split('T')[0]}`}
            />
          </div>
        </>
      )}
    </div>
  );
}

KPIs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default KPIs;
