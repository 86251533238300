import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Form, Button, Input,
} from 'antd';

function ConfirmModal({
  loading,
  onSubmit,
  onCancel,
  strConfirm,
  title,
}) {
  return (
    <Modal
      visible
      title={title}
      footer={null}
      closable={false}
    >
      <Form onFinish={onSubmit} disabled={loading}>
        <Form.Item
          name="text"
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                if (!value || strConfirm === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`Please type "${strConfirm}"`));
              },
            }),
          ]}
        >
          <Input
            placeholder={`Type "${strConfirm}" to confirm`}
            style={{ resize: 'none' }}
          />
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              textAlign: 'right',
              marginTop: 16,
            }}
          >
            <Button onClick={onCancel}>
              CANCEL
            </Button>
            {' '}
            <Button htmlType="Submit" loading={loading} type="primary">
              CONFIRM
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  strConfirm: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
};
ConfirmModal.defaultProps = {
  title: 'Confirm',
};

export default ConfirmModal;
