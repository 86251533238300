import React from 'react';
import { Typography } from 'antd';
import './index.less';
import PropTypes from 'prop-types';

const { Text } = Typography;

function KPICard({
  number,
  title,
  subTitle,
}) {
  return (
    <div className="KPICard">
      <Text copyable className="Number">{+number.toFixed(2)}</Text>
      <div className="Title">{title}</div>
      {subTitle && <div className="SubTitle">{subTitle}</div>}
    </div>
  );
}

KPICard.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};
KPICard.defaultProps = {
  subTitle: '',
};

export default KPICard;
