import Api from './Api';
import onlyEligibleFilter from '../utils/only-eligible-filter';
import { APP } from './Constants';

export const login = async (values) => {
  const result = await Api.post('/admin/login', values);
  return result.data;
};

export const logout = async () => {
  Api.post('/admin/logout');
};

export const getProfile = async () => {
  const result = await Api.post('/admin/get-profile');
  return result.data;
};

export const fetch = async ({
  app,
  model,
  method,
  filter,
  sort,
  projection,
  skip = 0,
  limit = 100,
}) => {
  const result = await Api.post('/admin/fetch', {
    app,
    model,
    method,
    filter,
    sort,
    projection,
    skip,
    limit,
  });
  return result.data;
};

const qToFilter = (q) => ({
  $or: [
    { name: { $regex: q, $options: 'i' } },
    { domain: { $regex: q, $options: 'i' } },
    { teamId: q },
    { state: q },
  ],
});
const fToFilter = (f, app) => ({
  ALL: {},
  FREE_TRIAL: { freeTrialStartDate: { $ne: null } },
  CUSTOMER: {
    $or: [
      { stripeSubscription: { $ne: null } },
      ...(app === 'seed' ? [] : [{ buddiesSubscription: true }]),
    ],
  },
})[f];

export const listWorkspaces = (app, f, limit, page, q = '') => fetch({
  app,
  model: 'SlackWorkspace',
  method: 'find',
  filter: {
    state: { $nin: ['jy_automation'] },
    $and: [
      fToFilter(f, app),
      ...(q ? [qToFilter(q)] : []),
    ],
  },
  sort: f === 'FREE_TRIAL' ? { freeTrialStartDate: -1 } : { _id: -1 },
  limit,
  skip: (page - 1) * limit,
});

export const countWorkspaces = (app, f, q) => fetch({
  app,
  model: 'SlackWorkspace',
  filter: {
    state: { $nin: ['jy_automation'] },
    ...fToFilter(f, app),
    ...(q ? qToFilter(q) : {}),
  },
  method: 'countDocuments',
});

const qToUserFilter = (q) => ({
  $or: [
    { 'data.real_name': { $regex: q, $options: 'i' } },
    { 'data.profile.real_name': { $regex: q, $options: 'i' } },
    { 'data.profile.email': { $regex: q, $options: 'i' } },
    { 'data.profile.title': { $regex: q, $options: 'i' } },
    { userId: q },
  ],
});
const fToUserFilter = (f) => ({
  ALL: {},
  ELIGIBLE: onlyEligibleFilter,
  BOT: { 'data.is_bot': true },
})[f];

export const listUsers = (app, f, page, teamId, q = '') => fetch({
  app,
  model: 'SlackUser',
  filter: {
    ...(teamId ? { teamId } : {}),
    ...fToUserFilter(f),
    ...(q ? qToUserFilter(q) : {}),
  },
  method: 'find',
  sort: { _id: -1 },
  skip: (page - 1) * 20,
  limit: 20,
});

export const countUsers = (app, f, teamId, q = '') => fetch({
  app,
  model: 'SlackUser',
  filter: {
    ...(teamId ? { teamId } : {}),
    ...fToUserFilter(f),
    ...(q ? qToUserFilter(q) : {}),
  },
  method: 'countDocuments',
});

export const getStats = async (app, teamIds) => {
  const result = await Api.post('/admin/stats', {
    app,
    teamIds,
  });
  return result.data;
};

export const deleteWorkspace = async (app, entityId) => Api.post('/admin/delete-workspace', {
  app,
  entityId,
});

export const grantFreePlan = async (app, entityId) => Api.post('/admin/grant-free-plan', {
  app,
  entityId,
});

export const getSlackAppData = async (appId) => {
  const result = await Api.post('/admin/slack-marketplace/get-app-data', {
    appId,
  });
  return result.data;
};

export const getKPIs = async (app) => {
  const result = await Api.post('/admin/kpis', {
    app,
  });
  return result.data;
};

export const getBuddiesMRR = async () => {
  const result = await Api.post('/admin/mrr', {});
  return result.data;
};

export const getAllKPIs = async () => {
  const [results, mrr] = await Promise.all([
    Promise.all(Object.keys(APP).sort().map(async (app) => {
      const data = await getKPIs(app);
      return {
        ...data,
        app,
      };
    })),
    getBuddiesMRR(),
  ]);
  return [
    {
      app: 'buddies',
      mrr,
      freeTrials: {
        currentPeriod: {
          start: results[0].freeTrials.currentPeriod.start,
          end: results[0].freeTrials.currentPeriod.end,
          count: 0,
          teamIds: [],
        },
        previousPeriod: {
          start: results[0].freeTrials.previousPeriod.start,
          end: results[0].freeTrials.previousPeriod.end,
          count: 0,
          teamIds: [],
        },
      },
    },
    ...results,
  ];
};
