/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import Workspaces from './Workspaces';
import Users from './Users';
import RankHistory from './RankHistory';
import KPIs from './KPIs';
import r from '../../utils/wrap-route-component';
import { APP } from '../../services/Constants';

function BuddyWrapper({ match, location }) {
  const app = APP[match.path.slice(1)];
  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[location.pathname.split('/').pop()]}
        style={{ borderBottom: 'none' }}
      >
        <Menu.Item key="workspaces">
          <a href={`#${match.path}/workspaces`}>Workspaces</a>
        </Menu.Item>
        <Menu.Item key="users">
          <a href={`#${match.path}/users`}>Users</a>
        </Menu.Item>
        {app.slackAppId && (
          <Menu.Item key="rank">
            <a href={`#${match.path}/rank`}>Rank</a>
          </Menu.Item>
        )}
        <Menu.Item key="kpis">
          <a href={`#${match.path}/kpis`}>KPIs</a>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path={`${match.path}/workspaces`} exact render={r(Workspaces)} />
        <Route path={`${match.path}/users`} exact render={r(Users)} />
        <Route path={`${match.path}/rank`} exact render={r(RankHistory)} />
        <Route path={`${match.path}/kpis`} exact render={r(KPIs)} />
        <Redirect from="*" to={`${match.path}/workspaces`} />
      </Switch>
    </>
  );
}

BuddyWrapper.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BuddyWrapper;
