/* eslint-disable no-nested-ternary */
import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import { BarChartOutlined } from '@ant-design/icons';
import { dateFromMongoId } from '@src/utils/mongo-id';

export const COMPETITOR = {
  birthdaybot: {
    img: 'https://avatars.slack-edge.com/2022-09-02/4028655733237_69a8eae2fd9c7ee3b6b9_48.png',
  },
  celebrations: {
    img: 'https://avatars.slack-edge.com/2023-05-17/5307136771632_23fc04d1c85ee699a7ab_48.png',
  },
  celebrations_by_airsp: {
    img: 'https://avatars.slack-edge.com/2023-05-16/5288408943857_dd9163fa2fbd9025ef1a_48.png',
  },
  heytaco: {
    img: 'https://avatars.slack-edge.com/2021-06-07/2144814816035_94f18513ec97458e7bb0_48.png',
  },
  matterbot: {
    img: 'https://avatars.slack-edge.com/2022-11-01/4299030558022_e2a5fae587705849f775_48.png',
  },
  bonusly: {
    img: 'https://avatars.slack-edge.com/2021-10-28/2661929214659_f41700f65ac4e426b25e_48.png',
  },
  shoutouts_by_airspeed: {
    img: 'https://avatars.slack-edge.com/2023-04-13/5127211793904_7088033763605e48a27a_48.png',
  },
  evergreen: {
    img: 'https://avatars.slack-edge.com/2023-05-30/5327792447847_3bb43fe865ece4bcc5e5_48.png',
  },
  engagewith: {
    img: 'https://avatars.slack-edge.com/2023-03-30/5022544196855_a000bed0e301f9f64919_48.jpg',
  },
  kudosing: {
    img: 'https://avatars.slack-edge.com/2021-08-13/2391014510897_40fec22846e73c878042_48.png',
  },
  kudosbot: {
    img: 'https://avatars.slack-edge.com/2023-02-06/4760731044611_ecb27ff0dc6bd0db7a20_48.png',
  },
  culturebot: {
    img: 'https://avatars.slack-edge.com/2022-05-16/3515634159655_6e18d0e2e4fda972f49e_48.png',
  },
  praisely: {
    img: 'https://avatars.slack-edge.com/2023-04-15/5139623395664_0649280d5251b2df33ed_48.png',
  },
  karma: {
    img: 'https://avatars.slack-edge.com/2022-05-27/3589622557075_b34dc94138c453d6c88f_48.png',
  },
  dankon: {
    img: 'https://avatars.slack-edge.com/2023-05-21/5298925769186_018ddd00b8a34f2e09a3_48.jpg',
  },
  imfrank: {
    img: 'https://avatars.slack-edge.com/2023-08-14/5735683573365_570b714f197102e08a1f_48.jpg',
  },
  kudos_by_deel: { img: 'https://avatars.slack-edge.com/2023-08-28/5813816642835_8005c4ff994e070d5c9f_48.png' },
  kudos3: { img: 'https://avatars.slack-edge.com/2023-12-01/6278380443075_dbbca51be8a8a0f9c802_48.png' },
  apprecibot: { img: 'https://avatars.slack-edge.com/2023-10-20/6067627830949_1be8fbc1ad4c65b1d34d_48.png' },
  hoyaapp: { img: 'https://avatars.slack-edge.com/2023-09-21/5924356618406_e0b79e8279dc95e00c08_48.png' },
  donut: { img: 'https://avatars.slack-edge.com/2022-09-09/4060168159219_5a8b151d919e04a8dd33_48.png' },
  lunchroulette: { img: 'https://avatars.slack-edge.com/2021-08-02/2335227334581_969f0b24917e19128476_48.png' },
  coffee_chat: { img: 'https://avatars.slack-edge.com/2023-11-24/6246729434258_4e38df24419204ff597e_48.png' },
  coffeetalk_by_airspee: { img: 'https://avatars.slack-edge.com/2023-07-14/5593172258401_6337014d649325db1660_48.png' },
  randomcoffee: { img: 'https://avatars.slack-edge.com/2022-10-11/4204093575171_db5534cd827feec7181a_48.png' },
  randomcoffees: { img: 'https://avatars.slack-edge.com/2022-02-08/3090082092641_b4b3c5d53252fb154b86_48.png' },
  catchup: { img: 'https://avatars.slack-edge.com/2022-01-07/2921791993586_38c8c8c38c5c6fd60799_48.jpg' },
  spotbot: { img: 'https://avatars.slack-edge.com/2023-01-22/4665258584535_9ee4c129bc9ee8cd88a2_48.jpg' },
  pringa: { img: 'https://avatars.slack-edge.com/2023-08-08/5722632410305_37358b55579ff4f7bce4_48.png' },
  dates_celebration_bot: { img: 'https://avatars.slack-edge.com/2023-10-30/6137171691568_f8490aa6ddfae287ce84_48.png' },
  surveys: { img: 'https://avatars.slack-edge.com/2022-07-26/3847384633893_bb2335a5848131e9b6d5_48.png' },
  surveyapp: { img: 'https://avatars.slack-edge.com/2023-06-07/5374890169447_2f8a296dec1295e15f16_48.png' },
  polly: { img: 'https://avatars.slack-edge.com/2022-05-16/3525831951110_93a5fc5000afac75d4b8_48.png' },
  simplepoll: { img: 'https://avatars.slack-edge.com/2022-08-14/3962301693312_3a4f2a76bb17bbdfc635_48.png' },
  jolly: { img: 'https://avatars.slack-edge.com/2023-12-29/6405256235987_49617a1efdfc9ce38b91_48.png' },
  'pulse-surveys-roots': { img: 'https://avatars.slack-edge.com/2023-01-24/4677485294327_f8ef6368d31167f5f812_48.png' },
  organice: { img: 'https://avatars.slack-edge.com/2023-10-11/6021682488082_e3c06bc95baebaf6c8f7_48.png' },
  'org-chart-deel': { img: 'https://avatars.slack-edge.com/2023-01-24/4694487007124_07888194087b874d310d_48.png' },
  org_by_roots: { img: 'https://avatars.slack-edge.com/2023-01-24/4694487007124_07888194087b874d310d_48.png' },
  cultureamp: { img: 'https://avatars.slack-edge.com/2023-06-01/5363583455620_a6a7e27f32cb29f666e1_48.png' },
  boost: { img: 'https://avatars.slack-edge.com/2023-04-24/5144701752839_26852f2343b83355c60e_48.png' },
  wrenly: { img: 'https://avatars.slack-edge.com/2024-02-12/6612557631447_5971bef20cbe2441845a_48.png' },
  incognito: { img: 'https://avatars.slack-edge.com/2022-10-17/4235766010164_e82cf1feab8f80211df2_48.png' },
  propsy: { img: 'https://avatars.slack-edge.com/2024-05-17/7132950372821_cc21625a16af43b4e772_48.png' },
  shuffl: { img: 'https://avatars.slack-edge.com/2024-06-28/7370246343168_f9a1060906fc363bf3de_48.png' },
  doozy: { img: 'https://avatars.slack-edge.com/2023-03-12/4937397323266_6590843c32254546cdd0_48.png' },
  trivia: { img: 'https://avatars.slack-edge.com/2023-12-13/6340410202181_4663f43d8f82698850b1_48.png' },
  'anonymous-bot': { img: 'https://avatars.slack-edge.com/2022-06-16/3693676174289_2223c97d6b50163298f9_48.png' },
  superpoll: { img: 'https://avatars.slack-edge.com/2023-09-24/5945162556724_d1ef5152ae9d482dc2ae_48.png' },
  advancedpoll: { img: 'https://avatars.slack-edge.com/2024-06-17/7284793315749_394236a6e55f3903b281_48.png' },
  botimum: { img: 'https://avatars.slack-edge.com/2024-01-31/6556094258614_081bade38b724d539c84_48.png' },
  ipoll: { img: 'https://avatars.slack-edge.com/2024-05-22/7145498019063_d08c48549bb644f8b4f0_48.png' },
  justpolls: { img: 'https://avatars.slack-edge.com/2024-06-10/7250968243187_ecd050d7d9f66431c279_48.png' },
  openpoll: { img: 'https://avatars.slack-edge.com/2021-10-14/2608140996484_d38a6c1dd3606c550ffb_48.png' },
  open_poll_plus: { img: 'https://avatars.slack-edge.com/2023-07-31/5662996973075_e89bc6202bc2e54dce08_48.png' },
  quickpoll: { img: 'https://avatars.slack-edge.com/2023-03-15/4956675591813_829cb0be6a1667407d12_48.png' },
  birthday: { img: 'https://avatars.slack-edge.com/2024-07-18/7426974522775_fd001c77156fda778976_48.webp' },
  standuply: { img: 'https://avatars.slack-edge.com/2019-01-24/532266289969_94f65d4b671f62e4379c_48.jpg' },
  troopr: { img: 'https://avatars.slack-edge.com/2024-08-22/7612159166629_275d7f152f69315f6171_48.png' },
  standup_buddy: { img: 'https://avatars.slack-edge.com/2024-08-22/7608407191830_306ee82cf4e47165d378_48.png' },
  alice: { img: 'https://avatars.slack-edge.com/2024-08-22/7615034951570_e81f5a3db19ca93f2a1e_48.png' },
  standup_and_prosper: { img: 'https://avatars.slack-edge.com/2024-08-22/7627700911089_9e49ba87c9a73c148aa5_48.png' },
  sup: { img: 'https://avatars.slack-edge.com/2024-08-22/7612150241861_079b1615725bd4558dde_48.png' },
  dixiapp: { img: 'https://avatars.slack-edge.com/2024-08-22/7608441587318_986333f085a33127e0fb_48.png' },
  standupwizard: { img: 'https://avatars.slack-edge.com/2024-08-22/7600516591975_ce293fcb1edeb17b5c0f_48.jpg' },
  standupbot: { img: 'https://avatars.slack-edge.com/2024-08-22/7614959533715_a645b84c1a847fd1f89d_48.png' },
  geekbot: { img: 'https://avatars.slack-edge.com/2024-08-22/7612184495653_31fdd9b144b54c9c9c3e_48.png' },
  jack: { img: 'https://avatars.slack-edge.com/2024-08-22/7615063477794_4d14a513a32b3ad8ad18_48.png' },
  dailybot: { img: 'https://avatars.slack-edge.com/2024-07-29/7479871971415_24d68d73c326c82dc4bf_48.jpg' },
  mycheckins: { img: 'https://avatars.slack-edge.com/2024-08-22/7600577874023_d12d9bb879b217c23384_48.png' },
  teamoclock: { img: 'https://avatars.slack-edge.com/2024-08-22/7600576543127_240fcdf44270b1ff0b4a_48.png' },
  standbot: { img: 'https://avatars.slack-edge.com/2024-08-22/7615019983043_449238420cf5edd6f495_48.png' },
  gather_ai: { img: 'https://avatars.slack-edge.com/2024-08-22/7615342319458_16ed787692707bef1b5a_48.png' },
  time: { img: 'https://avatars.slack-edge.com/2024-08-22/7617827495956_e3aee6aa464343ddfdc4_48.png' },
};

const billyEventDisplay = (key, stats) => (
  <span>
    <Tooltip
      placement="right"
      title={(
        <div>
          <div>{`(${(stats[`${key}Count`] - stats[`${key}OptOutCount`])}/${(stats.usersCount - stats[`${key}OptOutCount`])})`}</div>
          <div>{`${stats[`${key}OptOutCount`]} opt-out`}</div>
        </div>
      )}
    >
      {((stats.usersCount - stats[`${key}OptOutCount`]) === 0) ? '0%' : `${(100 * ((stats[`${key}Count`] - stats[`${key}OptOutCount`]) / (stats.usersCount - stats[`${key}OptOutCount`]))).toFixed(2)}%`}
    </Tooltip>
  </span>
);
const kudosInfo = (w) => {
  const channelStr = w.channels.map((c) => (
    <div key={c.id}>
      {c.info.is_private ? '🔒' : '#️⃣'}
      {`${c.info.name} - ${c.members.length} 👥`}
    </div>
  ));
  return (
    <div>
      <div>{w.channels.length > 0 ? channelStr : 'No channel'}</div>
      <div>{`Leaderboard: ${w.leaderboard.type} ${w.leaderboard.type !== 'NONE' ? `${w.leaderboard.weeklyUpdate.activated ? `🔔 (${moment().day(w.leaderboard.weeklyUpdate.weekDay).hour(w.leaderboard.weeklyUpdate.hour).format('dddd @ ha')})` : '🔕'}` : ''}`}</div>
      <div>{`Appreciation Day: ${w.appreciationDay.activated ? `✅ Every ${w.appreciationDay.frequency} week(s) on ${moment().day(w.appreciationDay.weekDay).format('dddd')}` : '❌'}`}</div>
      <br />
      <div>{`Company values (${w.companyValues.length}): ${w.companyValues.map((v) => v.name).join(', ') || '-'}`}</div>
    </div>
  );
};
const kudosColumns = [
  {
    title: '👤 30d',
    render: (w, stats) => (stats ? (
      <>
        {stats.activeUsersCount}
        {' '}
        <a
          href={stats.dashboardLink}
          target="_blank"
          rel="noreferrer"
        >
          <BarChartOutlined />
        </a>
      </>
    ) : ''),
  },
  {
    title: 'Last Activity',
    render: (w, stats) => (stats ? (
      <span
        style={{
          color: stats.latestShoutOutDate && moment(stats.latestShoutOutDate)
            .isBefore(moment()
              .subtract(7, 'days')) ? 'red' : undefined,
        }}
      >
        {stats.latestShoutOutDate
          ? moment(stats.latestShoutOutDate).format('YYYY-MM-DD @ HH:mm')
          : '-'}
      </span>
    ) : ''),
  },
];

const defaultStatus = (w) => ({
  LIVE: '🟢',
  PENDING: '🟠',
  UNINSTALLED: '🔴',
})[w.status === 'LIVE' && !w.installedAt ? 'PENDING' : w.status] || '';

const kudosStatus = (w) => (
  w.status === 'LIVE' && w.channels.length === 0
    ? '🟠'
    : defaultStatus(w)
);

export const APP = {
  alfy: {
    name: 'Alfy',
    emoji: '☕️',
    slackAppId: 'A0641FFPG80',
    status: (w) => (
      w.status === 'LIVE' && w.programs.length === 0
        ? '🟠'
        : defaultStatus(w)
    ),
    info: (w) => (
      <div>
        {w.programs.length === 0 && '-'}
        {w.programs.map((p) => (
          <div key={p._id}>
            {`${p.channels.map((c) => `${c.info.is_private ? '🔒' : '#️⃣'}${c.info.name} (${c.members.length}👥)`).join(' × ')} - 🔄 ${p.matchingFrequency} week(s) on ${moment().isoWeekday(p.matchingDay).format('dddd')} at ${moment().hour(p.matchingHour).format('ha')} - x${p.matchingGroupSize}`}
          </div>
        ))}
      </div>
    ),
    columns: [{
      title: '☕️',
      render: (w, stats) => (stats ? (
        stats.matchingItems.length ? (
          <Tooltip
            title={(
              <div>
                {stats.matchingItems.map((item) => (
                  <div key={item._id}>
                    {moment(item.date).format('YYYY-MM-DD @ HH:mm')}
                    {` • ${item.users}👤`}
                    {item.hasGroupConversation ? ` • ${item.matchSuccesses}/${item.matches}` : ''}
                  </div>
                ))}
              </div>
            )}
          >
            {`${stats.matchingItems.length} `}
            <a
              href={stats.dashboardLink}
              target="_blank"
              rel="noreferrer"
            >
              <BarChartOutlined />
            </a>
          </Tooltip>
        ) : '-'
      ) : null),
    }],
  },
  billy: {
    name: 'Billy',
    emoji: '🎂',
    slackAppId: 'A03UMMBSCH0',
    status: defaultStatus,
    info: (w) => {
      const hasChannel = w.version >= 'V3' ? w.channels.length > 0 : w.channel;
      const channelStr = w.version >= 'V3'
        ? w.channels.map((c) => (
          <div key={c.id}>
            {c.info.is_private ? '🔒' : '#️⃣'}
            {c.info.name}
            {` (${c.scope.toLowerCase()} - 🔄 ${c.autoCollect ? 'Yes' : 'No'}${c.scope === 'CHANNEL' ? ` - ${c.members.length}` : ''})`}
          </div>
        ))
        : (
          <div>
            {`#${w.channelInfo?.name}`}
          </div>
        );
      return (
        <div>
          {w.version}
          {hasChannel && channelStr}
        </div>
      );
    },
    columns: [
      {
        title: '🔄',
        render: (w) => (
          w.installedAt
            ? (
              w.version >= 'V3'
                ? (w.channels[0]?.autoCollect ? 'Yes' : 'No')
                : (w.autoCollect ? 'Yes' : 'No')
            )
            : '-'
        ),
      },
      {
        title: 'Stats 🎂',
        render: (w, stats) => (stats ? billyEventDisplay('birthday', stats) : ''),
      },
      {
        title: 'Stats 💼',
        render: (w, stats) => {
          if (!stats) return '';
          const has = (
            w.version >= 'V3'
              ? !!w.channels.find((c) => c.workAnniversary)
              : w.workAnniversary
          );
          if (!has) return '-';
          return billyEventDisplay('workAnniversary', stats);
        },
      },
      {
        title: '#🏆',
        render: (w, stats) => (stats ? stats.celebrationsCount : ''),
      },
      {
        title: '👀💸',
        render: (w) => (typeof w.hasSeenPricing === 'boolean' ? {
          true: 'Yes',
          false: 'No',
        }[w.hasSeenPricing] : '-'),
      },
    ],
  },
  clappy: {
    name: 'Clappy',
    emoji: '👏',
    slackAppId: 'A06A5FM9ZNX',
    status: kudosStatus,
    info: kudosInfo,
    columns: kudosColumns,
  },
  linky: {
    name: 'Linky',
    emoji: '🗂️',
    slackAppId: 'A06R8NK1JTF',
    status: (w, stats) => (stats ? (
      w.status === 'LIVE' && stats.noManagerCount === stats.usersCount
        ? '🟠'
        : defaultStatus(w)
    ) : null),
    info: () => '',
    columns: [
      {
        title: '🔄',
        render: (w) => `${w.orgChart.autoCollect ? 'Yes' : 'No'}`,
      },
      {
        title: '🔔',
        render: (w) => `${w.orgChart.changeNotifications ? 'Yes' : 'No'}`,
      },
      {
        title: '#️⃣',
        render: (w, stats) => (stats ? (
          stats.noManagerCount !== stats.usersCount ? (
            <Tooltip
              title={(
                <div>
                  {`${(stats.usersCount - stats.noManagerCount)}/${stats.usersCount}`}
                </div>
              )}
            >
              {`${(100 * ((stats.usersCount - stats.noManagerCount) / stats.usersCount)).toFixed(2)}% `}
              <a
                href={stats.dashboardLink}
                target="_blank"
                rel="noreferrer"
              >
                <BarChartOutlined />
              </a>
            </Tooltip>
          ) : '-'
        ) : null),
      },
    ],
  },
  pulsy: {
    name: 'Pulsy',
    emoji: '🐙',
    slackAppId: 'A0693E57F6V',
    status: (w, stats) => (stats ? (
      w.status === 'LIVE' && stats.surveys.length === 0 && stats.polls.length === 0
        ? '🟠'
        : defaultStatus(w)
    ) : null),
    info: () => '',
    columns: [
      {
        title: '📝',
        render: (w, stats) => (stats ? (
          stats.surveys.length ? (
            <Tooltip
              title={(
                <div>
                  {stats.surveys.map((survey) => (
                    <div key={survey._id}>
                      {{
                        DRAFT: '📝',
                        LIVE: '▶️',
                        PAUSED: '⏸️',
                      }[survey.status]}
                      {' '}
                      {survey.name}
                      <i>{` (${survey.status.toLowerCase()})`}</i>
                      {survey.frequency ? ` • 🔄 ${survey.frequency}` : ''}
                      {` • ${survey.questions}❓`}
                      {survey.status !== 'DRAFT' && ` • ${survey.responses}/${survey.userIds}`}
                    </div>
                  ))}
                </div>
              )}
            >
              {`${stats.surveys.filter((s) => s.status !== 'DRAFT').length} / ${stats.surveys.length} `}
              <a
                href={stats.dashboardLink}
                target="_blank"
                rel="noreferrer"
              >
                <BarChartOutlined />
              </a>
            </Tooltip>
          ) : '-'
        ) : null),
      },
      {
        title: '📊',
        render: (w, stats) => (stats ? (
          stats.polls.length ? (
            <Tooltip
              title={(
                <div>
                  {stats.polls.map((poll) => (
                    <div key={poll._id}>
                      {moment(dateFromMongoId(poll._id)).format('YYYY-MM-DD')}
                      {poll.frequency ? ` • 🔄 ${poll.frequency}` : ''}
                      {` • ${poll.questions}❓ • ${poll.respondents}🗣️`}
                    </div>
                  ))}
                </div>
              )}
            >
              {stats.polls.length}
            </Tooltip>
          ) : '-'
        ) : null),
      },
    ],
  },
  stany: {
    name: 'Stany',
    emoji: '📋',
    slackAppId: 'A07FX66V3EJ',
    status: (w) => (
      w.status === 'LIVE' && w.channels.length === 0
        ? '🟠'
        : defaultStatus(w)
    ),
    info: (w) => (
      <div>
        {w.channels.length === 0 && '-'}
        {w.channels.map((c) => (
          <div key={c.id}>
            {`${c.info.is_private ? '🔒' : '#️⃣'}${c.info.name} (${c.members.length}👥)`}
            {` - 🔄 ${c.daysOfWeek.map((n) => moment().isoWeekday(n).format('ddd')).join(', ')} at ${moment().hour(+c.time.split(':')[0]).minutes(+c.time.split(':')[1]).format('h:mma')} (${c.userTz === 'CHANNEL' ? c.tz : c.userTz})`}
          </div>
        ))}
      </div>
    ),
    columns: [{
      title: '📊',
      render: (w, stats) => (stats ? (
        stats.lastReports.length ? (
          <Tooltip
            title={(
              <div>
                {stats.lastReports.map((report) => (
                  <div key={report._id}>
                    {moment(dateFromMongoId(report._id)).format('YYYY-MM-DD')}
                    {` • ${report.questions}❓ • ${report.updates}/${report.users}🗣️`}
                  </div>
                ))}
              </div>
            )}
          >
            {stats.reportsCount}
          </Tooltip>
        ) : '-'
      ) : null),
    }],
  },
  ticky: {
    name: 'Ticky',
    emoji: '⏱️',
    slackAppId: '',
    status: (w, stats) => (stats ? (
      w.status === 'LIVE' && stats.projects.length === 0
        ? '🟠'
        : defaultStatus(w)
    ) : null),
    // liste des projets
    // reminders
    // # of users logs in last 30d
    info: (w) => (
      <div>
        {w.reminders.length === 0 && '-'}
        {w.reminders.map((r) => (
          <div key={r._id}>
            {`🔄 ${r.scope}${r.scope !== 'ALL' ? ` (${r.userIds.length})` : ''} - ${r.daysOfWeek.map((n) => moment().isoWeekday(n).format('ddd')).join(', ')} at ${moment().hour(+r.time.split(':')[0]).minutes(+r.time.split(':')[1]).format('h:mma')} (${r.tz})`}
          </div>
        ))}
      </div>
    ),
    columns: [{
      title: '💼',
      render: (w, stats) => (stats ? (
        stats.projects.length ? (
          <Tooltip
            title={(
              <div>
                {stats.projects.map((p, i, array) => (
                  <div key={p._id} style={i < array.length - 1 ? { marginBottom: 12 } : {}}>
                    <div><b>{`${p.name}${p.code ? ` (${p.code})` : ''}`}</b></div>
                    <div><i>{`🗓️ ${p.startDate} - ${p.endDate || 'now'}`}</i></div>
                    {p.customerId && <div><i>{`👤 ${p.customerId.name}`}</i></div>}
                    <div><i>{`👥 ${p.userIds.length}`}</i></div>
                  </div>
                ))}
              </div>
            )}
          >
            {stats.projects.length}
          </Tooltip>
        ) : '-'
      ) : null),
    }, {
      title: '👤 30d',
      render: (w, stats) => (stats ? (
        stats.projects.length > 0
          ? stats.activeUsers30dCount
          : '-'
      ) : null),
    }],
  },
  seed: {
    name: 'Seed',
    emoji: '🌱',
    slackAppId: 'A05EV5PDY2X',
    status: kudosStatus,
    info: kudosInfo,
    columns: [
      ...kudosColumns,
      {
        title: 'Next 🌳',
        render: (w) => w.nextPlantingDate,
      },
    ],
  },
};
