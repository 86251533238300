import React, { useState } from 'react';
import { useWindowSize } from '@src/utils/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { Menu, Button, Drawer } from 'antd';
import { logout } from '../../services/Admin';
import { APP } from '../../services/Constants';
import './index.less';

function AppBar() {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const size = useWindowSize();
  const mobile = size.width < 850;
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onClickLogout = () => {
    logout();
    localStorage.removeItem('sessionId');
    QueryClient.clear();
    history.push('/auth');
  };

  const onClick = () => {
    logout();
    localStorage.removeItem('sessionId');
    queryClient.clear();
    history.push('/auth');
  };

  return (
    <div id="AppBar">
      <img src="/logo_no_background.png" alt="buddies logo" width={45} />
      {mobile ? (
        <>
          <div className="Fill" />
          <Button type="secondary" onClick={showDrawer} className="menuIcon">
            <MenuOutlined />
          </Button>
        </>
      ) : (
        <>
          <Menu
            mode="horizontal"
            selectedKeys={[location.pathname.split('/')[1]]}
            // style={{ minWidth: '770px' }}
          >
            {Object.entries(APP).map(([key, { name, emoji }]) => (
              <Menu.Item key={key}>
                <a href={`#/${key}`}>
                  {`${emoji}`}
                  <span className="Hide_mobile">{` ${name}`}</span>
                </a>
              </Menu.Item>
            ))}
            <Menu.Item style={{ background: 'rgba(0,0,0,0.03)' }} key="stats">
              <a href="#/stats">
                📈&nbsp;
                <span className="Hide_mobile">{' Stats'}</span>
              </a>
            </Menu.Item>
          </Menu>
          <Button icon={<LogoutOutlined />} onClick={onClick}>
            <span className="Hide_mobile">Log out</span>
          </Button>
        </>
      )}
      <Drawer
        title="Menu"
        placement="top"
        onClose={onClose}
        visible={open}
        width="100%"
        height="80%"
        className="drawerMobile"
      >
        <div className="innerDrawer">
          <div className="mobileMenuInline">
            <Menu mode="inline" className="Mobile_menu">
              {Object.entries(APP).map(([key, { name, emoji }]) => (
                <Menu.Item key={key}>
                  <a href={`#/${key}`}>{`${emoji} ${name}`}</a>
                </Menu.Item>
              ))}
              <Menu.Item style={{ background: 'rgba(0,0,0,0.03)' }} key="stats">
                <a href="#/stats">📈&nbsp;Stats</a>
              </Menu.Item>
            </Menu>
          </div>
          <div className="bottomDrawer">
            <div className="bottomInner">
              <Button icon={<LogoutOutlined />} onClick={onClickLogout}>
                Log out
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default AppBar;
