import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAllKPIs } from '@src/services/Admin';
import Loader from '../../components/Loader';
import KPICard from '../../components/KPICard';
import { APP } from '../../services/Constants';

function Stats() {
  const { isLoading, data } = useQuery(
    ['getAllKPIs'],
    getAllKPIs,
    { cacheTime: 0 },
  );
  let total;
  if (data) {
    total = {
      mrr: data.reduce((t, item) => t + item.mrr, 0),
      currentFreeTrials: data
        .reduce((array, item) => [
          ...array,
          ...item.freeTrials.currentPeriod.teamIds,
          ...(item.freeTrials.currentPeriod.tenantIds || []),
        ], [])
        .filter((elem, index, array) => array.indexOf(elem) === index)
        .length,
      previousFreeTrials: data
        .reduce((array, item) => [
          ...array,
          ...item.freeTrials.previousPeriod.teamIds,
          ...(item.freeTrials.previousPeriod.tenantIds || []),
        ], [])
        .filter((elem, index, array) => array.indexOf(elem) === index)
        .length,
    };
  }
  return (
    <div id="Stats">
      {isLoading && <Loader />}
      {data && (
        <>
          <div>
            <div className="AppName">∑ Total</div>
            <div className="CardList">
              <KPICard number={total.mrr} title="$MRR" />
              <KPICard
                number={total.currentFreeTrials}
                title="Free Trials"
                subTitle={`${data[0].freeTrials.currentPeriod.start.split('T')[0]} → ${data[0].freeTrials.currentPeriod.end.split('T')[0]}`}
              />
              <KPICard
                number={total.previousFreeTrials}
                title="Free Trials"
                subTitle={`${data[0].freeTrials.previousPeriod.start.split('T')[0]} → ${data[0].freeTrials.previousPeriod.end.split('T')[0]}`}
              />
            </div>
          </div>
          {data.map((item) => (
            <div key={item.app}>
              <div className="AppName">
                {`${APP[item.app]?.emoji || '👯'} ${APP[item.app]?.name || 'Buddies (All apps)'}`}
              </div>
              <div className="CardList">
                <KPICard number={item.mrr} title="$MRR" />
                <KPICard
                  number={item.freeTrials.currentPeriod.count}
                  title="Free Trials"
                  subTitle={`${item.freeTrials.currentPeriod.start.split('T')[0]} → ${item.freeTrials.currentPeriod.end.split('T')[0]}`}
                />
                <KPICard
                  number={item.freeTrials.previousPeriod.count}
                  title="Free Trials"
                  subTitle={`${item.freeTrials.previousPeriod.start.split('T')[0]} → ${item.freeTrials.previousPeriod.end.split('T')[0]}`}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default Stats;
