import React from 'react';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Select, Table } from 'antd';
import moment from 'moment';
import { getSlackAppData } from '../../services/Admin';
import { APP } from '../../services/Constants';

function RankHistory({ match }) {
  // Filter
  const location = useLocation();
  const history = useHistory();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const nFilter = search.n || '30';
  const onFilterChange = (n) => {
    const newSearch = {
      ...search,
      n,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  // Data retrieving
  const [, app] = match.path.split('/');
  const { isLoading, data: appData } = useQuery(
    ['getSlackAppData', app],
    () => getSlackAppData(APP[app].slackAppId),
    { cacheTime: 0 },
  );
  const n = +nFilter || 30;
  const endDate = moment();
  const { dataSeries } = appData || { dataSeries: [] };
  const dataPointIndex = dataSeries.reduce((obj, s) => {
    s.dataPoints.forEach((dp) => {
      // eslint-disable-next-line no-param-reassign
      obj[`${s.categoryId}${s.type}${dp.date}`] = dp.rank;
    });
    return obj;
  }, {});
  return (
    <div id="RankHistory">
      <div className="Filters">
        <Select
          value={nFilter}
          style={{ width: 150, marginRight: 8 }}
          onChange={onFilterChange}
        >
          <Select.Option value="14">Last 14 days</Select.Option>
          <Select.Option value="30">Last 30 days</Select.Option>
          <Select.Option value="90">Last 90 days</Select.Option>
        </Select>
      </div>
      <Table
        dataSource={dataSeries}
        loading={isLoading}
        pagination={false}
        rowKey={(s) => `${s.categoryId}${s.type}`}
        size="small"
        scroll={{ x: 1500 }}
      >
        <Table.Column
          title=""
          render={(_, s) => (
            <div style={{ whiteSpace: 'nowrap' }}>
              <b>{`${s.type === 'RANK' ? '#️⃣' : '🌟'} ${s.category.name}`}</b>
            </div>
          )}
        />
        {new Array(n).fill(null).map((_, i) => (
          <Table.Column
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            title={moment(endDate).subtract(i, 'days').format('MMM D')}
            render={(x, s) => dataPointIndex[`${s.categoryId}${s.type}${moment(endDate).subtract(i, 'days').format('YYYY-MM-DD')}`] || '-'}
          />
        )).reverse()}
      </Table>
    </div>
  );
}

RankHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default RankHistory;
